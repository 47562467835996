import { Toast } from 'bootstrap';

document.addEventListener("DOMContentLoaded", function() {
  const userManagement = document.querySelector('.users-inspectors-management');
  if (userManagement === null) {
    return
  }

  const inviteUserPopoup = document.querySelector('.invite-user-popup');
  const addAdminPopoup = document.querySelector('.add-admin-popup');
  const toggleAddUser = document.querySelector('.add-user');
  const toggleAddAdmin = document.querySelector('.add-admin');
  const closeAdminPopups = document.querySelector('.close-admin-popup');
  const closeUserPopups = document.querySelector('.close-user-popup');

  toggleAddUser.addEventListener('click', () => {
    inviteUserPopoup.classList.toggle('d-none');
    addAdminPopoup.classList.add('d-none');
  });
  toggleAddAdmin.addEventListener('click', () => {
    addAdminPopoup.classList.toggle('d-none');
    inviteUserPopoup.classList.add('d-none');
  });
  closeAdminPopups.addEventListener('click', () => {
    addAdminPopoup.classList.add('d-none');
  });
  closeUserPopups.addEventListener('click', () => {
    inviteUserPopoup.classList.add('d-none');
  });

  const form = document.getElementById('new_admin'); 
  const formContainer = document.querySelector('.add-admin-popup');

  form.addEventListener('ajax:success', (event) => {
    const [data, status, xhr] = event.detail;
    formContainer.classList.add('d-none');
    form.reset();
    document.querySelectorAll('.error-message').forEach((div) => {
      div.textContent = '';
    });
    document.querySelector('#toast-notification .toast-body').textContent = '管理者が正常に作成されました。';
    const toastElement = document.getElementById('toast-notification');
    const toast = new Toast(toastElement);
    toast.show();
  });

  form.addEventListener('ajax:error', (event) => {
    const [data, status, xhr] = event.detail;
    document.querySelectorAll('.error-message').forEach((div) => {
      div.textContent = '';
    });

    data.errors.forEach((error) => {
      const field = error.split(' ')[0].toLowerCase(); // Get the field name from the error message

      switch (field) {
        case "メールアドレス":
          const errorDivEmail = document.getElementById(`email-error`);
          if (errorDivEmail) {
            errorDivEmail.textContent = error;
          }
          break;
        case "パスワード":
          const errorDivPas = document.getElementById(`password-error`);
          if (errorDivPas) {
            errorDivPas.textContent = error;
          }
          break;
        case "パスワード確認":
          const errorDivPasCfm = document.getElementById(`password_confirmation-error`);
          if (errorDivPasCfm) {
            errorDivPasCfm.textContent = error;
          }
          break;
        default:
          break;
      }
    });
  });
});
