import { Toast } from 'bootstrap';

document.addEventListener("DOMContentLoaded", function() {
  const newCompany = document.querySelector('.new-company');
  if (newCompany == undefined) {
    return
  }

  const addCompanyButton = document.querySelector('.add-company-button');
  const closePopup = document.querySelector('.close-popup');
  const addCompanyContainer = document.querySelector('.add-company-container');

  addCompanyButton.addEventListener('click', () => {
    addCompanyContainer.classList.toggle('d-none');
  });
  closePopup.addEventListener('click', () => {
    addCompanyContainer.classList.add('d-none');
  });

  const form = document.getElementById('company-form');
  form.addEventListener('ajax:error', function(event) {
    const [data, status, xhr] = event.detail;

    document.querySelectorAll('.error-message').forEach((div) => {
      div.textContent = '';
    });

    if (data.errors) {
      data.errors.forEach((error) => {
        if (error.includes("会社")) {
          document.getElementById('error-name').textContent = error.replace(/Admins email|Admins password confirmation|Admins password/g, '').trim();
        } else if (error.includes("email")) {
          document.getElementById('error-admin-email').textContent = error.replace(/Admins email|Admins password confirmation|Admins password/g, '').trim();
        } else if (error.includes("confirmation")) {
          document.getElementById('error-admin-password-confirmation').textContent = error.replace(/Admins email|Admins password confirmation|Admins password/g, '').trim();
        } else if (error.includes("password")) {
          document.getElementById('error-admin-password').textContent = error.replace(/Admins email|Admins password confirmation|Admins password/g, '').trim();
        }
      });
    }
  });

  form.addEventListener('ajax:success', function(event) {
    const [data, status, xhr] = event.detail;
    document.querySelector('#toast-notification .toast-body').textContent = data.message;
    const toastElement = document.getElementById('toast-notification');
    const toast = new Toast(toastElement);
    toast.show();
    addCompanyContainer.classList.add('d-none');
    form.reset();
    document.querySelectorAll('.error-message').forEach((div) => {
      div.textContent = '';
    });
  });
});
