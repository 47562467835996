import { Toast } from 'bootstrap';

document.addEventListener('DOMContentLoaded', function() {
  const confirmChange = document.querySelector('.confirm-change');
  const paidContainer = document.querySelector('.paid-container');
  const paidButton = document.querySelector('.paid-button');
  const confirmText = document.querySelector('.confirm-text'); 
  const paidText = document.querySelector('.paid-text-label'); 
  if (paidContainer) {
    paidContainer.addEventListener('click', () => {
      if (paidContainer.classList.contains('inactive')) {
        confirmText.textContent = '有料モードに変更しますか？';
      }
      else {
        confirmText.textContent = '無料モードに変更しますか？';
      }
    });
    confirmChange.addEventListener('click', async function() {
      paidContainer.classList.toggle('inactive');
      paidButton.classList.toggle('inactive');
      if (paidContainer.classList.contains('inactive')) {
        paidText.textContent = '無料モード';
      }
      else {
        paidText.textContent = '有料モード';
      }
      // Toggle the class
      try {
        const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        const response = await fetch('/admin/subscription_products/toggle', {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken
          }
        });
        if (!response.ok) {
          throw new Error('Failed to toggle subscription mode');
        }
        if (response.ok) {
        } else {
          paidContainer.classList.toggle('inactive');
          paidButton.classList.toggle('inactive');
          if (paidContainer.classList.contains('inactive')) {
            paidText.textContent = '無料モード';
          }
          else {
            paidText.textContent = '有料モード';
          }
          document.querySelector('#toast-notification .toast-body').textContent = 'Update not successful';
          const toastElement = document.getElementById('toast-notification');
          const toast = new Toast(toastElement);
          toast.show();
        }
      } catch (error) {
        paidContainer.classList.toggle('inactive');
        paidButton.classList.toggle('inactive');
        document.querySelector('#toast-notification .toast-body').textContent = error;
        const toastElement = document.getElementById('toast-notification');
        const toast = new Toast(toastElement);
        toast.show();
      }
      
    });
  }
});
