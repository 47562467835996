import { Toast } from "bootstrap";

// app/assets/javascripts/search.js
document.addEventListener("DOMContentLoaded", function () {
  const reportsManagement = document.querySelector(".reports-management");
  if (reportsManagement === null) {
    return;
  }

  const monthlyTag = document.querySelector(".monthly-report-tag");
  const yearlyTag = document.querySelector(".yearly-report-tag");
  const monthlySearch = document.querySelector(".monthly-search-fields");
  const yearlySearch = document.querySelector(".yearly-search-fields");
  const searchPopup = document.getElementById("search-popup");
  const searchResults = document.getElementById("search-results");
  const clientChangeAuthority = document.querySelector(
    ".client-change-authority"
  );
  const clientShareAuthority = document.querySelector(
    ".client-share-authority"
  );
  const exportButton = document.getElementById("export-csv-button");
  const monthUserSearch = document.querySelector(".m-user-search");
  const yearUserSearch = document.querySelector(".y-user-search");
  const monthUserSearchResult = document.querySelector(
    ".user-search-result-monthly"
  );
  const yearUserSearchResult = document.querySelector(
    ".user-search-result-yearly"
  );
  const monthClientSearch = document.querySelector(".m-client-search");
  const yearClientSearch = document.querySelector(".y-client-search");
  const monthClientSearchResult = document.querySelector(
    ".client-search-result-monthly"
  );
  const yearClientSearchResult = document.querySelector(
    ".client-search-result-yearly"
  );

  const editPopup = document.getElementById("edit-popup");
  const editContent = document.getElementById("edit-content");
  const closePopupButton = document.getElementById("close-popup");
  const activeClientName = document.getElementById("active-client-name");
  const editClientContainer = document.querySelector(".edit-client-container");
  const editClientName = document.getElementById("edit-client-name");
  const saveClientNameButton = document.getElementById("save-client-name");
  const cancelClientNameButton = document.getElementById("cancel-client-name");
  const editClientNameIcon = document.getElementById("edit-client-name-icon");
  const gsearch = document.getElementById("gsearch");
  const gsearch2 = document.getElementById("gsearch2");
  const annualReportRadio = document.getElementById("annual-report");
  const monthlyReportRadio = document.getElementById("monthly-report");
  const reportSelect = document.getElementById("report-select");
  const reportDate = document.getElementById("report-date");
  const reportYear = document.getElementById("report-year");
  const radioContainer = document.getElementById("radio-container");
  const dateTimeContainer = document.getElementById("date-time-container");
  const monthlyRadio = document.querySelector(
    'input[name="report-type"][value="monthly"]'
  );
  const yearlyRadio = document.querySelector(
    'input[name="report-type"][value="yearly"]'
  );
  const monthlyReportsContainer = document.querySelector(
    "#monthlyReportsContainer"
  );
  const yearlyReportsContainer = document.querySelector(
    "#yearlyReportsContainer"
  );

  const monthlyReportDetail = document.querySelector(".monthly-report-details");
  const yearlyReportDetail = document.querySelector(".yearly-report-details");

  const saveYearlyButton = document.querySelector(".save-yearly-report");
  const reportIdData = document.querySelector("#report-id");
  const reportTypeData = document.querySelector("#report-type");
  const csvDiv = document.querySelector("#csv-div");
  const userIdDiv = document.querySelector("#user-id");
  const referenceIdDiv = document.querySelector("#reference-id");
  const exportCsvLink = document.querySelector("#export-csv-link");

  const fields = {
    type: 'select[name="type"]',
    "weather.weatherType": 'select[name="weather.weatherType"]',
    "weather.temperatureOutside": 'input[name="weather.temperatureOutside"]',
    "weather.humidityRoom": 'input[name="weather.humidityRoom"]',
    "weather.temperatureRoom": 'input[name="weather.temperatureRoom"]',
    referenceNumber: 'input[name="referenceNumber"]',
    "meterCurrent.allDayPower": 'input[name="meterCurrent.allDayPower"]',
    "meterCurrent.activePower": 'input[name="meterCurrent.activePower"]',
    "meterCurrent.reactivePower": 'input[name="meterCurrent.reactivePower"]',
    "meterCurrent.maximumPowerDemand":
      'input[name="meterCurrent.maximumPowerDemand"]',
    "meterConfirmed.allDayPower": 'input[name="meterConfirmed.allDayPower"]',
    "meterConfirmed.activePower": 'input[name="meterConfirmed.activePower"]',
    "meterConfirmed.reactivePower":
      'input[name="meterConfirmed.reactivePower"]',
    "meterConfirmed.maximumPowerDemand":
      'input[name="meterConfirmed.maximumPowerDemand"]',
    "highVoltageBoard.voltage.RS": 'input[name="highVoltageBoard.voltage.RS"]',
    "highVoltageBoard.voltage.ST": 'input[name="highVoltageBoard.voltage.ST"]',
    "highVoltageBoard.voltage.TR": 'input[name="highVoltageBoard.voltage.TR"]',
    "highVoltageBoard.current.R": 'input[name="highVoltageBoard.current.R"]',
    "highVoltageBoard.current.S": 'input[name="highVoltageBoard.current.S"]',
    "highVoltageBoard.current.T": 'input[name="highVoltageBoard.current.T"]',
    "highVoltageBoard.power": 'input[name="highVoltageBoard.power"]',
    "highVoltageBoard.powerFactor":
      'input[name="highVoltageBoard.powerFactor"]',
    "inspection.compartmentSwitch":
      'select[name="inspectionPoints.compartmentSwitch"]',
    "inspection.highVoltageCabinet":
      'select[name="inspectionPoints.highVoltageCabinet"]',
    "inspection.serviceLine": 'select[name="inspectionPoints.serviceLine"]',
    "inspection.cable": 'select[name="inspectionPoints.cable"]',
    "inspection.disconnector": 'select[name="inspectionPoints.disconnector"]',
    "inspection.electricFuse": 'select[name="inspectionPoints.electricFuse"]',
    "inspection.circuitBreaker":
      'select[name="inspectionPoints.circuitBreaker"]',
    "inspection.highVoltageSwitch":
      'select[name="inspectionPoints.highVoltageSwitch"]',
    "inspection.transformer": 'select[name="inspectionPoints.transformer"]',
    "inspection.electricCapacitor":
      'select[name="inspectionPoints.electricCapacitor"]',
    "inspection.seriesReactor": 'select[name="inspectionPoints.seriesReactor"]',
    "inspection.lightningArrester":
      'select[name="inspectionPoints.lightningArrester"]',
    "inspection.gaugeTransformer":
      'select[name="inspectionPoints.gaugeTransformer"]',
    "inspection.busLine": 'select[name="inspectionPoints.busLine"]',
    "inspection.protectiveRelay":
      'select[name="inspectionPoints.protectiveRelay"]',
    "inspection.switchBoard": 'select[name="inspectionPoints.switchBoard"]',
    "inspection.groundingWire": 'select[name="inspectionPoints.groundingWire"]',
    "inspection.powerReceivingRoom":
      'select[name="inspectionPoints.powerReceivingRoom"]',
    "inspection.cubicle": 'select[name="inspectionPoints.cubicle"]',
    "inspection.powerDistributionFacility":
      'select[name="inspectionPoints.powerDistributionFacility"]',
    "inspection.wiring": 'select[name="inspectionPoints.wiring"]',
    "inspection.lowVoltageEquipment":
      'select[name="inspectionPoints.lowVoltageEquipment"]',
    "inspection.chargingDevice":
      'select[name="inspectionPoints.chargingDevice"]',
    "inspection.battery": 'select[name="inspectionPoints.battery"]',
    "inspection.generator": 'select[name="inspectionPoints.generator"]',
    "inspection.starter": 'select[name="inspectionPoints.starter"]',
  };

  monthlyTag.addEventListener("click", () => {
    showFields("monthly");
    reportTypeData.dataset.Type = "monthly";
  });
  yearlyTag.addEventListener("click", () => {
    showFields("yearly");
    reportTypeData.dataset.Type = "yearly";
  });

  const monthAndPagy = document.querySelector(".monthAndPagy");
  const yearAndPagy = document.querySelector(".yearAndPagy");

  function showFields(type) {
    if (type === "monthly") {
      monthlyTag.classList.add("active");
      monthlySearch.classList.replace("d-none", "d-flex");
      monthAndPagy.classList.remove("d-none");
      yearlyTag.classList.remove("active");
      yearlySearch.classList.replace("d-flex", "d-none");
      yearAndPagy.classList.add("d-none");
    } else {
      monthlyTag.classList.remove("active");
      monthlySearch.classList.replace("d-flex", "d-none");
      monthAndPagy.classList.add("d-none");
      yearlyTag.classList.add("active");
      yearlySearch.classList.replace("d-none", "d-flex");
      yearAndPagy.classList.remove("d-none");
    }
  }

  const userId = userIdDiv.dataset.UserId;

  if (userId) {
    handleClientSearch(userId);
    document.querySelector(".label-username").classList.remove("d-none");
  }

  const searchForms = [
    { form: document.getElementById("searchForm"), input: gsearch },
    { form: document.getElementById("searchForm2"), input: gsearch2 },
  ];

  function handleSearch(query) {
    fetch(`/admin/users/search?query=${query}`, {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    })
      .then((response) => response.json())
      .then((users) => {
        searchResults.innerHTML = "";
        users.forEach((user) => {
          const userDiv = document.createElement("div");
          userDiv.classList.add("user-result", "rounded");
          userDiv.innerHTML = `<div>${user.name}</div><div>${user.email}</div>`;
          userDiv.addEventListener("click", () => {
            handleClientSearch(user.id);
            shareReportButton.classList.replace("invisible", "visible");
          });
          searchResults.appendChild(userDiv);
        });
        searchPopup.style.display = "flex";
      });
  }

  function handleClientSearch(userId) {
    userIdDiv.dataset.UserId = userId;
    fetch(`/admin/clients/search?user_id=${userId}`, {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    })
      .then((response) => response.json())
      .then((clients) => {
        searchPopup.style.display = "none";
        displayClients(clients);
      });
  }

  function appendClientOption(element, client) {
    let clientOption = document.createElement("option");
    clientOption.value = client.id;
    clientOption.textContent = shortString(client.name, 20);
    element.appendChild(clientOption);
  }

  function displayClients(clients) {
    clientChangeAuthority.innerHTML =
      '<option value="" selected disabled>クライアントを選択して</option>';
    clientShareAuthority.innerHTML = "";
    clients.forEach((client) => {
      appendClientOption(clientChangeAuthority, client);
      appendClientOption(clientShareAuthority, client);
    });
  }

  function setReferenceId(clientId, userId) {
    fetch(
      `/admin/reference_user_clients/search?user_id=${userId}&client_id=${clientId}`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
      }
    )
      .then((response) => response.json())
      .then((reference) => {});
  }

  function shortString(string, nub) {
    let shortString =
      string.length > nub ? string.substring(0, nub) + "..." : string;
    return shortString;
  }

  function toggleEditMode(enable) {
    if (enable) {
      editPopup.style.display = "flex";
    } else {
      editPopup.style.display = "none";
    }
  }
  cancelClientNameButton.addEventListener("click", () => {
    toggleEditMode(false);
  });

  editClientNameIcon.addEventListener("click", () => {
    toggleEditMode(true);
    editClientName.focus();
  });

  // Prevent closing the popup when clicking inside the content
  editContent.addEventListener("click", (event) => {
    event.stopPropagation();
  });

  editPopup.addEventListener("click", () => {
    toggleEditMode(false);
  });

  saveClientNameButton.addEventListener("click", () => {
    const newName = editClientName.value.trim();
    const csrfToken = saveClientNameButton.dataset.csrfToken;

    if (newName && activeClientName.dataset.clientId) {
      fetch(`/admin/clients/${activeClientName.dataset.clientId}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        body: JSON.stringify({ client: { name: newName } }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            activeClientName.firstChild.textContent = shortString(
              data.client.name,
              20
            );
            const activeClientElement = document.querySelector(
              `[data-client-id="${activeClientName.dataset.clientId}"]`
            );
            activeClientElement.firstChild.textContent = data.client.name;
            toggleEditMode(false);
            document.querySelector(
              "#toast-notification .toast-body"
            ).textContent = "クライアントは正常に更新されました。";
            const toastElement = document.getElementById("toast-notification");
            const toast = new Toast(toastElement);
            toast.show();
          } else {
            document.querySelector(
              "#toast-notification .toast-body"
            ).textContent =
              "クライアントの更新中にエラーが発生しました: " +
              data.errors.join(", ");
            const toastElement = document.getElementById("toast-notification");
            const toast = new Toast(toastElement);
            toast.show();
          }
        });
    }
  });

  searchForms.forEach(({ form, input }) => {
    form.addEventListener("click", function (event) {
      event.preventDefault();
      const query = input.value;
      gsearch.value = gsearch2.value = query;
      handleSearch(query);
    });
  });

  closePopupButton.addEventListener("click", function () {
    searchPopup.style.display = "none";
  });

  function fetchReports(reportType, param_value = null) {
    let url = `/admin/clients/${currentClientId}/${reportType}_reports`;
    if (reportType == "monthly") {
      url += `?date=${param_value}`;
    }
    if (reportType == "yearly") {
      url += `?year=${param_value}`;
    }

    fetch(url, {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    })
      .then((response) => response.json())
      .then((reports) => {
        reportSelect.innerHTML = `<option selected disabled>報告を選択</option>`;
        let reportIds = [];
        reports.forEach((report) => {
          const option = document.createElement("option");
          option.value = report.id;
          // Format the date based on reportType
          reportSelect.dataset.id << report.id;
          if (reportType === "yearly") {
            const date = new Date(report.created_at);
            option.textContent = date.toLocaleDateString("ja-JP", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
            });
          } else if (reportType === "monthly") {
            const time = new Date(report.created_at);
            option.textContent = time.toLocaleTimeString("ja-JP");
          } else {
            option.textContent = report.created_at; // Default behavior if neither yearly nor monthly
          }
          option.dataset.reportType = reportType;
          reportSelect.appendChild(option);
          reportSelect.dataset.reportType = reportType;
          reportIds.push(report.id);
        });
        reportSelect.dataset.reportIds = JSON.stringify(reportIds);
        exportCsvLink.href = `/admin/${reportSelect.dataset.reportType}_reports/export_csv.csv?report_ids=${reportSelect.dataset.reportIds}`;
      });
  }

  const searchMonthlyButton = document.querySelector(".search-monthly-button");
  const searchYearlyButton = document.querySelector(".search-yearly-button");
  const mStart = document.querySelector(".m-start-search");
  const mEnd = document.querySelector(".m-end-search");
  const yStart = document.querySelector(".y-start-search");
  const yEnd = document.querySelector(".y-end-search");

  searchMonthlyButton.addEventListener("click", () => {
    navigateToSearch(
      "monthly",
      mStart.value,
      mEnd.value,
      monthUserSearch.dataset.userId,
      monthUserSearch.value,
      null,
      null,
      monthClientSearch.dataset.clientId,
      monthClientSearch.value
    );
  });

  searchYearlyButton.addEventListener("click", () => {
    navigateToSearch(
      "yearly",
      null,
      null,
      yearUserSearch.dataset.userId,
      yearUserSearch.value,
      yStart.value,
      yEnd.value,
      yearClientSearch.dataset.clientId,
      yearClientSearch.value
    );
  });

  function navigateToSearch(
    reportType,
    startTime = null,
    endTime = null,
    userId = null,
    userQuery = null,
    startYear = null,
    endYear = null,
    clientId = null,
    clientQuery = null
  ) {
    let url = `/admin/${reportType}_reports/search`;
    const params = new URLSearchParams();

    if (startTime) params.append("start_time", startTime);
    if (endTime) params.append("end_time", endTime);
    if (startYear) params.append("start_year", startYear);
    if (endYear) params.append("end_year", endYear);
    if (userQuery && userQuery.trim() !== "") {
      if (userId) params.append("user_id", userId);
      params.append("user_query", userQuery.trim());
    }
    if (clientQuery && clientQuery.trim() !== "") {
      if (clientId) params.append("client_id", clientId);
      params.append("client_query", clientQuery.trim());
    }

    window.location.href = `${url}?${params.toString()}`;
  }

  const reportsData = monthlyReportsContainer.dataset.reports;
  const reportsDataYearly = yearlyReportsContainer.dataset.reports;
  if (reportsData) {
    renderMonthlyReports(JSON.parse(reportsData));
  }
  if (reportsDataYearly) {
    renderYearlyReports(JSON.parse(reportsDataYearly));
  }

  function fetchAllReports(
    reportType,
    startTime = null,
    endTime = null,
    userId = null
  ) {
    let url = `/admin/${reportType}_reports/search`;
    const params = new URLSearchParams();

    if (startTime) params.append("start_time", startTime);
    if (endTime) params.append("end_time", endTime);
    if (userId) params.append("user_id", userId);

    fetch(`${url}?${params.toString()}`, {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    })
      .then((response) => response.json())
      .then((reports) => {
        alertIfNoReportFound(reports);
        displayAllReport(reports, reportType);
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  }

  function alertIfNoReportFound(reports) {
    if (reports.data.length === 0) {
      document.querySelector("#toast-notification .toast-body").textContent =
        "レポートが見つかりませんでした";
      const toastElement = document.getElementById("toast-notification");
      const toast = new Toast(toastElement);
      toast.show();
    }
  }

  function displayAllReport(reports, reportType) {
    if (reportType === "monthly") {
      renderMonthlyReports(reports.data);
    } else {
      renderYearlyReports(reports.data);
    }
  }

  function showReportDisplay(reportType) {
    csvDiv.classList.remove("d-none");
    if (reportType === "yearly") {
      yearlyReportDetail.style.display = "block";
      monthlyReportDetail.style.display = "none";
    } else if (reportType === "monthly") {
      monthlyReportDetail.style.display = "flex";
      yearlyReportDetail.style.display = "none";
    }
  }

  function hideReportDisplay() {
    monthlyReportDetail.style.display = "none";
    yearlyReportDetail.style.display = "none";
  }

  function fetchReportDetails(reportType, reportId) {
    fetch(`/admin/${reportType}_reports/${reportId}`, {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    })
      .then((response) => response.json())
      .then((report) => {
        if (reportType === "monthly") {
          populateFields(report);
          populateNotesFields(report.notes);
        } else if (reportType === "yearly") {
          populateYearlyReportFields(report);
        }
      })
      .catch((error) => {
        document.querySelector(
          "#toast-notification .toast-body"
        ).textContent = `Error fetching report: ${error}`;
        const toastElement = document.getElementById("toast-notification");
        const toast = new Toast(toastElement);
        toast.show();
      });
  }

  function getCurrentReportType() {
    if (monthlyRadio.checked) {
      return "monthly";
    } else if (yearlyRadio.checked) {
      return "yearly";
    }
  }

  // Event listener for report type radio buttons
  document.querySelectorAll('input[name="report-type"]').forEach((radio) => {
    radio.addEventListener("change", function () {
      if (this.value === "yearly") {
        reportDate.style.display = "none";
        reportYear.style.display = "block";
        reportYear.classList.remove("d-none");
        fetchReports("yearly", reportYear.value);
      } else if (this.value === "monthly") {
        reportDate.style.display = "block";
        reportYear.style.display = "none";
        fetchReports("monthly", reportDate.value);
      }
    });
  });

  // Event listener for date input
  reportDate.addEventListener("change", function () {
    fetchReports("monthly", this.value);
  });

  reportYear.addEventListener("change", function () {
    fetchReports("yearly", this.value);
  });

  // Event listener for report select
  reportSelect.addEventListener("change", function () {
    const reportId = this.value;
    const reportType = annualReportRadio.checked ? "yearly" : "monthly";
    showReportDisplay(reportType);
    fetchReportDetails(reportType, reportId);
    reportIdData.dataset.Id = this.value;
    reportTypeData.dataset.Type = reportType;
  });

  function populateNotesFields(notes, notesContainer) {
    notesContainer.innerHTML = ""; // Clear existing notes

    if (notes === null) {
      return;
    }
    Object.keys(notes).forEach((key, index) => {
      const noteValue = notes[key];

      // Create a new note field
      const noteField = document.createElement("div");
      noteField.className =
        "d-flex flex-column bd-highlight article-last pt-1 mx-1";
      noteField.innerHTML = `
        <div class="label-input-last flex-shrink-1 bd-highlight text-center">記事${
          index + 1
        }</div>
        <div class="w-100 bd-highlight" style="min-width: "200px">
          <input type="text" id="${key}" name="${key}" size="5" value="${noteValue}" class="input-last" />
        </div>
      `;

      notesContainer.appendChild(noteField);
    });
  }

  function formatDateTime(datetimeStr) {
    const date = new Date(datetimeStr);

    // Extract date components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    // Construct formatted date-time string
    return `${year}/${month}/${day} - ${hours}:${minutes}:${seconds}`;
  }

  // Function to populate fields based on report data
  function populateFields(report, reportElement) {
    reportIdData.dataset.Id = report.id;

    const userName = reportElement.querySelector(".show-user-name");
    const clientName = reportElement.querySelector(".show-client-name");

    let name = report.user_name || report.user_email;
    userName.textContent = `点検者: ${shortString(name, 13)}`;
    userName.dataset.name = "点検者: " + name;
    userName.dataset.nameShort = name.length >= 13;
    userName.addEventListener("click", (e) => {
      showHideName(e);
    });

    clientName.textContent = `顧客: ${shortString(report.client_name, 13)}`;
    clientName.dataset.name = "顧客: " + report.client_name;
    clientName.dataset.nameShort = report.client_name.length >= 13;
    clientName.addEventListener("click", (e) => {
      showHideName(e);
    });

    const machineContainer = reportElement.querySelector("#machineContainer"); // Container where machines will be appended
    const machineTemplate = reportElement.querySelector("#machineTemplate"); // Reference to the template
    updateStatusField(report, reportElement);
    reportElement.querySelector("#exact-date-time").textContent =
      formatDateTime(report.created_at);

    // Clear existing machines
    while (machineContainer.firstChild) {
      machineContainer.removeChild(machineContainer.firstChild);
    }

    for (let i = 0; i < report.machineList.length; i++) {
      const newMachine = document.importNode(machineTemplate.content, true);
      updateMachineEntry(newMachine, i);
      machineContainer.appendChild(newMachine);

      const machinePrefix = `machineList.${i}`;
      fields[`${machinePrefix}.rs`] = `input[name="${machinePrefix}.rs"]`;
      fields[`${machinePrefix}.st`] = `input[name="${machinePrefix}.st"]`;
      fields[`${machinePrefix}.tr`] = `input[name="${machinePrefix}.tr"]`;
      fields[`${machinePrefix}.r`] = `input[name="${machinePrefix}.r"]`;
      fields[`${machinePrefix}.s`] = `input[name="${machinePrefix}.s"]`;
      fields[`${machinePrefix}.t`] = `input[name="${machinePrefix}.t"]`;
      fields[
        `${machinePrefix}.temperature`
      ] = `input[name="${machinePrefix}.temperature"]`;
      fields[
        `${machinePrefix}.leakage`
      ] = `input[name="${machinePrefix}.leakage"]`;
    }

    populateTimeFields(report, reportElement);

    for (const key in fields) {
      if (fields.hasOwnProperty(key)) {
        const value = getNestedProperty(report, key);
        const selector = fields[key];
        const element = reportElement.querySelector(selector);
        if (element) {
          if (element.tagName === "SELECT") {
            if (value !== undefined) {
              const options = element.options;
              for (let i = 0; i < options.length; i++) {
                if (options[i].value == value) {
                  // using == to compare number and string
                  element.selectedIndex = i;
                  break;
                }
              }
            } else {
              element.selectedIndex = -1; // Reset the select element
            }
          } else {
            element.value = value !== undefined ? value : ""; // Reset to empty string if value is undefined
          }
        }
      }
    }

    const notesContainer = reportElement.querySelector("#notes-container");
    populateNotesFields(report.notes, notesContainer);
  }

  function resetTimeFields(reportElement) {
    reportElement.querySelector('input[name="dateIn"]').value = "";
    reportElement.querySelector('input[name="timeIn"]').value = "";
    reportElement.querySelector('input[name="dateOut"]').value = "";
    reportElement.querySelector('input[name="timeOut"]').value = "";
  }

  function populateTimeFields(report, reportElement) {
    resetTimeFields(reportElement);
    if (report.dateTime) {
      const partsIn = report.dateTime.split(/[-T:]/);
      const dateTimeInUTC = new Date(
        Date.UTC(partsIn[0], partsIn[1] - 1, partsIn[2], partsIn[3], partsIn[4])
      );
      if (!isNaN(dateTimeInUTC.getTime())) {
        reportElement.querySelector('input[name="dateIn"]').value =
          dateTimeInUTC.toISOString().slice(0, 10); // YYYY-MM-DD
        reportElement.querySelector('input[name="timeIn"]').value =
          dateTimeInUTC.toISOString().slice(11, 16); // HH:MM
      }
    }

    if (report.dateTimeOut) {
      const partsOut = report.dateTimeOut.split(/[-T:]/);
      const dateTimeOutUTC = new Date(
        Date.UTC(
          partsOut[0],
          partsOut[1] - 1,
          partsOut[2],
          partsOut[3],
          partsOut[4]
        )
      );
      if (!isNaN(dateTimeOutUTC.getTime())) {
        reportElement.querySelector('input[name="dateOut"]').value =
          dateTimeOutUTC.toISOString().slice(0, 10); // YYYY-MM-DD
        reportElement.querySelector('input[name="timeOut"]').value =
          dateTimeOutUTC.toISOString().slice(11, 16); // HH:MM
      }
    }
  }

  function updateMachineEntry(machine, index) {
    let machineTitle = machine.querySelector(".machine-title");
    machineTitle.textContent = `変圧器${index + 1}`;
    const inputs = machine.querySelectorAll("input");
    inputs.forEach((input) => {
      const nameParts = input.name.split(".");
      nameParts[1] = index; // Update the index in the name
      input.name = nameParts.join(".");
      input.value = ""; // Clear the input value
    });
  }

  // Function to get nested property from object
  function getNestedProperty(obj, key) {
    const keyParts = key.split(".");

    return keyParts.reduce((o, p) => {
      // Check if p is a valid number
      const isArrayIndex = !isNaN(p);
      return o ? (isArrayIndex ? o[parseInt(p)] : o[p]) : undefined;
    }, obj);
  }

  function renderMonthlyReports(reports) {
    if (reports === null) {
      return;
    }
    const reportsContainer = document.getElementById("monthlyReportsContainer");
    const reportTemplate = document.getElementById("monthlyReportTemplate");

    // Clear existing reports
    reportsContainer.innerHTML = "";
    let reportIds = [];
    reports.forEach((report) => {
      const newReport = document.importNode(reportTemplate.content, true);
      const reportElement = newReport.querySelector(".monthly-report-details");
      reportElement.dataset.reportId = report.id; // Assign report ID

      // Populate the report fields
      populateFields(report, reportElement);

      // Add scroll horizontally
      reportElement.addEventListener("wheel", transformScroll);

      // Append the report element to the container
      reportsContainer.appendChild(newReport);

      // Add event submit edit
      const saveMonthlyButton = reportElement.querySelector(
        ".save-monthly-report"
      );
      saveMonthlyButton.dataset.reportId = report.id;
      saveMonthlyButton.dataset.userId = report.user_id;
      reportElement.dataset.userId = report.user_id;
      saveMonthlyButton.addEventListener("click", (e) => {
        submitForm(e.target.dataset.reportId, e.target.dataset.userId);
      });

      reportIds.push(report.id);
    });
    reportIds = [...new Set(reportIds)];
    exportButton.dataset.reportIds = JSON.stringify(reportIds);
    exportCsvLink.href = `/admin/${reportTypeData.dataset.Type}_reports/export_csv.csv?report_ids=${reportIds}`;
  }

  function collectFormData(reportElement) {
    const formData = {};

    for (const key in fields) {
      if (fields.hasOwnProperty(key)) {
        const selector = fields[key];
        const element = reportElement.querySelector(selector);
        if (element) {
          // Split the key by dot to handle nested structures
          const keys = key.split(".");
          let currentObj = formData;

          // Create nested objects if they don't exist
          for (let i = 0; i < keys.length - 1; i++) {
            const nestedKey = keys[i];
            currentObj[nestedKey] = currentObj[nestedKey] || {};
            currentObj = currentObj[nestedKey];
          }

          // Assign the value to the deepest nested key
          const lastKey = keys[keys.length - 1];
          currentObj[lastKey] =
            element.tagName === "SELECT"
              ? parseInt(element.value)
              : element.value.trim();
          if (element.tagName === "SELECT") console.log(typeof element.value);
        }
      }
    }

    // Collect machine list data
    const machineList = [];
    const machines = reportElement.querySelectorAll(".machine-infor");
    machines.forEach((machine, index) => {
      const machineData = {
        rs: machine.querySelector(`input[name="machineList.${index}.rs"]`)
          .value,
        st: machine.querySelector(`input[name="machineList.${index}.st"]`)
          .value,
        tr: machine.querySelector(`input[name="machineList.${index}.tr"]`)
          .value,
        r: machine.querySelector(`input[name="machineList.${index}.r"]`).value,
        s: machine.querySelector(`input[name="machineList.${index}.s"]`).value,
        t: machine.querySelector(`input[name="machineList.${index}.t"]`).value,
        leakage: machine.querySelector(
          `input[name="machineList.${index}.leakage"]`
        ).value,
        temperature: machine.querySelector(
          `input[name="machineList.${index}.temperature"]`
        ).value,
      };
      machineList.push(machineData);
    });

    formData.machineList = machineList;

    //  Collect monthly notes
    const notes = {};

    const inputs = reportElement.querySelectorAll(
      '.notes-container input[type="text"]'
    );

    inputs.forEach((input) => {
      const key = input.name;
      const value = input.value;
      notes[key] = value;
    });
    formData.notes = notes;

    return formData;
  }

  function renderYearlyReports(reports) {
    if (reports === null) {
      return;
    }
    const reportsContainer = document.getElementById("yearlyReportsContainer");
    const reportTemplate = document.getElementById("yearlyReportTemplate");

    // Clear existing reports
    reportsContainer.innerHTML = "";
    let reportIds = [];
    reports.forEach((report) => {
      const newReport = document.importNode(reportTemplate.content, true);
      const reportElement = newReport.querySelector(".yearly-report-details");
      reportElement.dataset.reportId = report.id; // Assign report ID

      // Populate the report fields
      populateYearlyReportFields(report, reportElement);

      // Add scroll horizontally
      reportElement.addEventListener("wheel", transformScroll);

      // Append the report element to the container
      reportsContainer.appendChild(newReport);

      // Add event submit edit
      const saveYearlyButton = reportElement.querySelector(
        ".save-yearly-report"
      );

      saveYearlyButton.dataset.reportId = report.id;
      saveYearlyButton.dataset.userId = report.user_id;
      reportElement.dataset.userId = report.user_id;
      saveYearlyButton.addEventListener("click", (e) => {
        submitYearlyForm(saveYearlyButton.dataset.reportId, saveYearlyButton.dataset.userId);
      });

      reportIds.push(report.id);
    });
    exportButton.dataset.reportIds = JSON.stringify(reportIds);
    exportCsvLink.href = `/admin/${reportTypeData.dataset.Type}_reports/export_csv.csv?report_ids=${reportIds}`;
  }

  function showHideName(e) {
    let element = e.target;
    let new_name = element.dataset.name;
    let old_name = element.textContent;
    if (element.dataset.nameShort) {
      element.textContent = new_name;
      element.dataset.name = old_name;
      element.classList.toggle("min-width-200");
    }
  }

  // Function to populate the form fields with the data from the yearlyReport object
  function populateYearlyReportFields(yearlyReport, reportElement) {
    const userName = reportElement.querySelector(".show-user-name");
    let name = yearlyReport.user_name || yearlyReport.user_email;
    userName.textContent = `利用者: ${shortString(name, 13)}`;
    userName.dataset.name = "利用者: " + name;
    userName.dataset.nameShort = name.length >= 13;
    userName.addEventListener("click", (e) => {
      showHideName(e);
    });

    const clientName = reportElement.querySelector(".show-client-name");
    clientName.textContent = `顧客: ${shortString(
      yearlyReport.client_name,
      13
    )}`;
    clientName.dataset.name = "顧客: " + yearlyReport.client_name;
    clientName.dataset.nameShort = yearlyReport.client_name.length >= 13;
    clientName.addEventListener("click", (e) => {
      showHideName(e);
    });

    reportElement.querySelector("#machine-tests-section").innerHTML = "";
    reportElement.querySelector("#ground-resistance-section").innerHTML = "";
    reportElement.querySelector(
      "#low-voltage-circuit-options-section"
    ).innerHTML = "";
    reportElement.querySelector(
      "#low-voltage-circuit-notes-section"
    ).innerHTML = "";
    reportElement.querySelector(
      "#high-voltage-circuit-values-section"
    ).innerHTML = "";
    reportElement.querySelector("#GR-section").innerHTML = "";
    reportElement.querySelector("#OCR-section").innerHTML = "";
    reportElement.querySelector("#LGR-section").innerHTML = "";
    reportElement.querySelector("#yearly-report-year").value = "";
    
    if (yearlyReport.year !== '') {
      const inputDate = yearlyReport.year;

      const utcDate = new Date(inputDate);

      const offset = 9 * 60;
      const japanTime = new Date(utcDate.getTime() + offset * 60 * 1000);

      const formattedJapanDate = japanTime.toISOString().slice(0, 16);

      reportElement.querySelector("#yearly-report-year").value = formattedJapanDate;
    }

    const date = new Date(yearlyReport.year);
    reportElement.querySelector('#exact-date-time').textContent = (yearlyReport.year == "") ? "" : date.getFullYear();
    updateStatusField(yearlyReport, reportElement);

    // Function to populate a template
    function populateTemplate(templateId, data, containerId) {
      const template = reportElement.querySelector(`#${templateId}`).innerHTML;
      let populatedTemplate = template.replace(/{{index}}/g, data.index);
      populatedTemplate = populatedTemplate.replace(
        /{{indexTitle}}/g,
        data.index + 1
      );
      containerId.insertAdjacentHTML("beforeend", populatedTemplate);
    }

    // Populate Machine Tests
    yearlyReport.machineTests.forEach((test, index) => {
      populateTemplate(
        "machine-test-template",
        { index: index },
        reportElement.querySelector("#machine-tests-section")
      );
      for (let i = 0; i < 6; i++) {
        reportElement.querySelector(
          `[name="machineTests.${index}.${i}"]`
        ).value = test[i] || "";
      }
    });

    // Populate Ground Resistance
    yearlyReport.groundResistance.forEach((gr, index) => {
      populateTemplate(
        "ground-resistance-template",
        { index: index },
        reportElement.querySelector("#ground-resistance-section")
      );
      reportElement.querySelector(
        `[name="groundResistance.${index}.measurement"]`
      ).value = gr.measurement || "";
      reportElement.querySelector(
        `[name="groundResistance.${index}.results"]`
      ).value = gr.results || "";
    });

    // Populate high-voltage-circuit-values
    yearlyReport.highVoltageCircuitValues.forEach((data, index) => {
      populateTemplate(
        "high-voltage-circuit-values-template",
        { index: index },
        reportElement.querySelector("#high-voltage-circuit-values-section")
      );
      reportElement.querySelector(
        `[name="highVoltageCircuitValues.${index}.measurement1"]`
      ).value = data.measurement1 || "";
      reportElement.querySelector(
        `[name="highVoltageCircuitValues.${index}.measurement2"]`
      ).value = data.measurement2 || "";
      reportElement.querySelector(
        `[name="highVoltageCircuitValues.${index}.ratio"]`
      ).value = data.ratio || "";
      reportElement.querySelector(
        `[name="highVoltageCircuitValues.${index}.results"]`
      ).value = data.results || "";
      reportElement.querySelector(
        `[name="highVoltageCircuitValues.${index}.measurement1"]`
      ).value = data.measurement1 || "";
    });

    // Populate lowVoltageCircuitOptions
    yearlyReport.lowVoltageCircuitOptions.forEach((data, index) => {
      populateTemplate(
        "low-voltage-circuit-options-template",
        { index: index },
        reportElement.querySelector("#low-voltage-circuit-options-section")
      );
      reportElement.querySelector(
        `[name="lowVoltageCircuitOptions.${index}"]`
      ).value = data || "";
    });

    yearlyReport.lowVoltageCircuitNotes.forEach((data, index) => {
      populateTemplate(
        "low-voltage-circuit-notes-template",
        { index: index },
        reportElement.querySelector("#low-voltage-circuit-notes-section")
      );
      reportElement.querySelector(
        `[name="lowVoltageCircuitNotes.${index}"]`
      ).value = data || "";
    });

    yearlyReport.GR.forEach((data, index) => {
      populateTemplate(
        "GR-template",
        { index: index },
        reportElement.querySelector("#GR-section")
      );
      reportElement.querySelector(
        `[name="GR.${index}.minimumOperatingCurrent"]`
      ).value = data.minimumOperatingCurrent || "";
      reportElement.querySelector(`[name="GR.${index}.operatingTime"]`).value =
        data.operatingTime || "";
      reportElement.querySelector(
        `[name="GR.${index}.minimumOperatingVoltage"]`
      ).value = data.minimumOperatingVoltage || "";
      reportElement.querySelector(`[name="GR.${index}.test"]`).value =
        data.test || "";
      reportElement.querySelector(`[name="GR.${index}.phase"]`).value =
        data.phase || "";
      reportElement.querySelector(`[name="GR.${index}.SOG"]`).value = data.SOG;
      reportElement.querySelector(`[name="GR.${index}.inertia"]`).value =
        data.inertia || "";
      reportElement.querySelector(`[name="GR.${index}.results"]`).value =
        data.results || "";
    });

    yearlyReport.OCR.forEach((data, index) => {
      populateTemplate(
        "OCR-template",
        { index: index },
        reportElement.querySelector("#OCR-section")
      );
      reportElement.querySelector(`[name="OCR.${index}.results"]`).value =
        data.results || "";
      reportElement.querySelector(
        `[name="OCR.${index}.timeLimitStart"]`
      ).value = data.timeLimitStart || "";
      reportElement.querySelector(
        `[name="OCR.${index}.timeLimit300Percent"]`
      ).value = data.timeLimit300Percent || "";
      reportElement.querySelector(
        `[name="OCR.${index}.timeLimitInterlock"]`
      ).value = data.timeLimitInterlock || "";
      reportElement.querySelector(`[name="OCR.${index}.instantStart"]`).value =
        data.instantStart || "";
      reportElement.querySelector(
        `[name="OCR.${index}.instantOperationTime"]`
      ).value = data.instantOperationTime || "";
    });

    yearlyReport.LGR.forEach((data, index) => {
      populateTemplate(
        "LGR-template",
        { index: index },
        reportElement.querySelector("#LGR-section")
      );
      reportElement.querySelector(
        `[name="LGR.${index}.minimumOperatingCurrent"]`
      ).value = data.minimumOperatingCurrent || "";
      reportElement.querySelector(`[name="LGR.${index}.test"]`).value =
        data.test || "";
      reportElement.querySelector(`[name="LGR.${index}.results"]`).value =
        data.results || "";
    });
  }

  function updateStatusField(data, reportElement) {
    const statusField = reportElement.querySelector("#status");
    let status = "未定"; // Default status: "Undetermined"

    if (data.isComplete) {
      status = "完了"; // "Complete"
      statusField.classList.add("bg--dark-green");
    } else if (data.isChanged) {
      status = "変更あり"; // "Changed"
      statusField.classList.add("bg--dark-gray");
    } else if (data.deleted) {
      status = "削除済み"; // "Deleted"
      statusField.classList.add("bg--dark-red");
    } else if (data.isPendingComplete) {
      status = "未完了"; // "Pending Complete"
      statusField.classList.add("bg--blue-default");
    } else {
      status = "未完了"; // "Pending Complete"
      statusField.classList.add("bg-info");
    }

    statusField.textContent = status;
  }

  function collectYearlyData(reportElement) {
    const yearlyData = {
      machineTests: [],
      groundResistance: [],
      highVoltageCircuitValues: [],
      lowVoltageCircuitOptions: [],
      lowVoltageCircuitNotes: [],
      GR: [],
      OCR: [],
      LGR: [],
      year: "",
    };
    const jpTime = reportElement.querySelector(
      `[name="yearly-report-year"]`
    ).value;

    const jpDate = new Date(`${jpTime}:00.000Z`);
    const offset = 9 * 60;
    const utcTime = new Date(jpDate.getTime() - offset * 60 * 1000);

    yearlyData.year = utcTime.toISOString()

    function collectSectionData(prefix, hasIndex = true, transformFunc = null) {
      const elements = reportElement.querySelectorAll(`[name^="${prefix}."]`);
      elements.forEach((element) => {
        const parts = element.name.split(".");
        const index = parseInt(parts[1], 10);
        const property = parts.slice(2).join(".");
        const value =
          element.tagName === "SELECT"
            ? parseInt(element.value)
            : element.value.trim();

        if (hasIndex) {
          if (!yearlyData[prefix][index]) {
            yearlyData[prefix][index] = transformFunc !== null ? [] : {};
          }
          if (transformFunc) {
            transformFunc(yearlyData[prefix][index], property, value);
          } else {
            yearlyData[prefix][index][property] = value;
          }
        } else {
          yearlyData[prefix][index] = value;
        }
      });
    }

    function transformMachineTests(data, property, value) {
      data[property] = value;
    }

    collectSectionData("machineTests", true, transformMachineTests);
    collectSectionData("groundResistance");
    collectSectionData("highVoltageCircuitValues");
    collectSectionData("GR");
    collectSectionData("OCR");
    collectSectionData("LGR");
    collectSectionData("lowVoltageCircuitOptions", false);
    collectSectionData("lowVoltageCircuitNotes", false);

    return yearlyData;
  }

  function submitForm(report_id, user_id) {
    const reportElement = document.querySelector(
      `.monthly-report-details[data-report-id="${report_id}"][data-user-id="${user_id}"]`
    );
    const formData = collectFormData(reportElement);
    const saveMonthlyButton = reportElement.querySelector(
      ".save-monthly-report"
    );
    const csrfTokenMonthly = saveMonthlyButton.dataset.csrfToken;

    const dateIn = reportElement.querySelector('input[name="dateIn"]').value;
    const timeIn = reportElement.querySelector('input[name="timeIn"]').value;
    const dateOut = reportElement.querySelector('input[name="dateOut"]').value;
    const timeOut = reportElement.querySelector('input[name="timeOut"]').value;

    const dateTimeIn = `${dateIn}T${timeIn}:00.000Z`;
    const dateTimeOut = `${dateOut}T${timeOut}:00.000Z`;

    formData.dateTime = dateTimeIn;
    formData.dateTimeOut = dateTimeOut;

    fetch(`/admin/monthly_reports/${report_id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfTokenMonthly,
      },
      body: JSON.stringify({ monthly_report: formData }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("ネットワークの応答が正常ではありませんでした。");
        }
        return response.json();
      })
      .then((data) => {
        document.querySelector(
          "#toast-notification .toast-body"
        ).textContent = `レポートは正常に更新されました`;
        const toastElement = document.getElementById("toast-notification");
        const toast = new Toast(toastElement);
        toast.show();
        // Handle success, e.g.,
      })
      .catch((error) => {
        document.querySelector(
          "#toast-notification .toast-body"
        ).textContent = `レポートの更新中にエラーが発生しました: ${error}`;
        const toastElement = document.getElementById("toast-notification");
        const toast = new Toast(toastElement);
        toast.show();
        // Handle errors
      });
  }

  function submitYearlyForm(report_id, user_id) {
    const reportElement = document.querySelector(
      `.yearly-report-details[data-report-id="${report_id}"][data-user-id="${user_id}"]`
    );
    const formData = collectYearlyData(reportElement);
    const saveYearlyButton = reportElement.querySelector(".save-yearly-report");
    const csrfTokenYearly = saveYearlyButton.dataset.csrfToken;

    fetch(`/admin/yearly_reports/${report_id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfTokenYearly,
      },
      body: JSON.stringify({ yearly_report: formData }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("ネットワークの応答が正常ではありませんでした。");
        }
        return response.json();
      })
      .then((data) => {
        document.querySelector(
          "#toast-notification .toast-body"
        ).textContent = `レポートは正常に更新されました`;
        const toastElement = document.getElementById("toast-notification");
        const toast = new Toast(toastElement);
        toast.show();
        // Handle success, e.g.,
      })
      .catch((error) => {
        document.querySelector(
          "#toast-notification .toast-body"
        ).textContent = `レポートの更新中にエラーが発生しました: ${error}`;
        const toastElement = document.getElementById("toast-notification");
        const toast = new Toast(toastElement);
        toast.show();
        // Handle errors
      });
  }

  // Share report functions

  const sharePopupBgXCover = document.querySelector(".share-popup-bg-cover");
  const shareReportButton = document.querySelector(".share-report-button");
  const sharePopup = document.getElementById("share-popup");
  const closeSharePopup = document.getElementById("close-share-popup");
  const changeShareButton = document.getElementById("change-share-button");
  const currentAuthority = document.getElementById("current-authority");
  const newAuthorityButton = document.getElementById("new-authority-button");
  const transmitShareButton = document.getElementById("transmit-share-button");
  const changeAuthorityWindow = document.getElementById(
    "change-authority-window"
  );
  const changeAuthorityForm = document.getElementById("change-authority-form");
  const transferAuthorityWindow = document.querySelector(
    ".transfer-authority-window"
  );
  const transferAuthorityForm = document.getElementById(
    "transfer-authority-form"
  );
  const alreadyHasAuthorityPopup = document.getElementById(
    "already-has-authority-popup"
  );
  const closeAlreadyHasAuthorityPopup =
    document.getElementById("close-popup-center");
  const removeAuthorityPopup = document.getElementById(
    "remove-authority-popup"
  );
  const cancelDeleteShare = document.getElementById("cancel-delete-share");
  const confirmDelete = document.getElementById("confirm-delete");

  // Show the pop up share popup
  shareReportButton.addEventListener("click", () => {
    sharePopup.classList.toggle("d-none");
    sharePopupBgXCover.classList.toggle("d-none");
  });
  closeSharePopup.addEventListener("click", () => {
    sharePopup.classList.add("d-none");
    sharePopupBgXCover.classList.add("d-none");
  });

  // Show and hide change authority window
  changeShareButton.addEventListener("click", () => {
    changeAuthorityWindow.classList.toggle("d-none");
    transferAuthorityWindow.classList.add("d-none");
  });

  // Show and hide transfer authority window
  transmitShareButton.addEventListener("click", () => {
    transferAuthorityWindow.classList.toggle("d-none");
    changeAuthorityWindow.classList.add("d-none");
  });

  // Close already has authority popup
  closeAlreadyHasAuthorityPopup.addEventListener("click", () => {
    alreadyHasAuthorityPopup.classList.add("d-none");
  });

  // Change authority submission
  changeAuthorityForm.addEventListener("submit", (event) => {
    event.preventDefault();
    const formData = new FormData(changeAuthorityForm);
    const role = formData.get("role");
    let method = "PATCH";

    if (role === "delete") {
      method = "DELETE";
    }

    fetch("/admin/reference_user_clients/" + clientChangeAuthority.value, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: JSON.stringify({
        reference_user_client: {
          role: role,
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          document.querySelector(
            "#toast-notification .toast-body"
          ).textContent = data.error;
          const toastElement = document.getElementById("toast-notification");
          const toast = new Toast(toastElement);
          toast.show();
        } else {
          window.location.reload();
          document.querySelector(
            "#toast-notification .toast-body"
          ).textContent = data.message;
          const toastElement = document.getElementById("toast-notification");
          const toast = new Toast(toastElement);
          toast.show();
        }
      })
      .catch((error) => {
        console.error("エラー:", error);
        document.querySelector(
          "#toast-notification .toast-body"
        ).textContent = `リクエストの処理中にエラーが発生しました。`;
        const toastElement = document.getElementById("toast-notification");
        const toast = new Toast(toastElement);
        toast.show();
      });
  });

  // Handle form submission
  transferAuthorityForm.addEventListener("submit", (event) => {
    event.preventDefault();

    const formData = new FormData(transferAuthorityForm);
    const userEmail = formData.get("user_email");
    const role = formData.get("role");
    const client_id = clientShareAuthority.dataset.clientId;

    // Example AJAX request to create the reference_user_client
    fetch("/admin/reference_user_clients", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: JSON.stringify({
        reference_user_client: {
          user_email: userEmail,
          role: role,
          client_id: client_id,
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          if (data.error === "すでに権限を持っている") {
            alreadyHasAuthorityPopup.classList.remove("d-none");
          } else {
            document.querySelector(
              "#toast-notification .toast-body"
            ).textContent = `エラーが発生しました: ${data.error}`;
            const toastElement = document.getElementById("toast-notification");
            const toast = new Toast(toastElement);
            toast.show();
          }
        } else {
          document.querySelector(
            "#toast-notification .toast-body"
          ).textContent = data.message;
          const toastElement = document.getElementById("toast-notification");
          const toast = new Toast(toastElement);
          toast.show();
          transferAuthorityWindow.classList.add("d-none");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  });

  // Export csv file:
  exportButton.addEventListener("click", function () {
    if (
      exportButton.dataset.reportIds &&
      exportButton.dataset.reportIds === "[]"
    ) {
      document.querySelector(
        "#toast-notification .toast-body"
      ).textContent = `現在、ダウンロードできるレポートはありません`;
      const toastElement = document.getElementById("toast-notification");
      const toast = new Toast(toastElement);
      toast.show();
      return;
    }
    fetch(
      `/admin/${reportTypeData.dataset.Type}_reports/export_csv.csv?report_ids=${exportButton.dataset.reportIds}`,
      {
        method: "GET",
      }
    );
  });

  function transformScroll(event) {
    if (!event.deltaY) {
      return;
    }

    event.currentTarget.scrollLeft += event.deltaY + event.deltaX;
    event.preventDefault();
  }

  var elements = document.querySelectorAll(".scroll-container");
  elements.forEach((element) => {
    element.addEventListener("mouseenter", () => {
      element.addEventListener("wheel", transformScroll);
    });
  });
  // Code if want a little delay before allowing scroll-horizontal
  // elements.forEach(element => {
  //   let scrollTimeout;

  //   element.addEventListener('mouseenter', () => {
  //     scrollTimeout = setTimeout(() => {
  //       element.addEventListener('wheel', transformScroll);
  //     }, 200);
  //   });

  //   element.addEventListener('mouseleave', () => {
  //     clearTimeout(scrollTimeout);
  //     element.removeEventListener('wheel', transformScroll);
  //   });
  // });

  // Search user name
  let timeoutId = null;
  const roleChangeSearchUser = document.querySelector(
    ".role-change-search-user"
  );
  const roleChangeSearchUserResult = document.querySelector(
    ".role-change-search-user-result"
  );
  const roleInviteSearchUser = document.querySelector(
    ".role-invite-search-user"
  );
  const roleInviteSearchUserResult = document.querySelector(
    ".role-invite-search-user-result"
  );
  const shareRole = document.querySelector(".share-role");
  const shareAuthorityButton = document.querySelector(
    ".share-authority-button"
  );

  const inputList = [
    monthUserSearch,
    yearUserSearch,
    roleChangeSearchUser,
    roleInviteSearchUser,
  ];
  const resultList = [
    monthUserSearchResult,
    yearUserSearchResult,
    roleChangeSearchUserResult,
    roleInviteSearchUserResult,
  ];
  const clientList = [clientChangeAuthority, clientShareAuthority];
  const roleList = [currentAuthority, shareRole];
  const submitList = [newAuthorityButton, shareAuthorityButton];
  const clientInputList = [monthClientSearch, yearClientSearch];
  const clientResultList = [monthClientSearchResult, yearClientSearchResult];

  userOrClientSearchPopup(0, inputList, fetchUsers);
  userOrClientSearchPopup(1, inputList, fetchUsers);
  userOrClientSearchPopup(2, inputList, fetchUsers, chooseClient);
  userOrClientSearchPopup(3, inputList, fetchUsers, chooseClient);
  userOrClientSearchPopup(0, clientInputList, fetchClients);
  userOrClientSearchPopup(1, clientInputList, fetchClients);
  hideOnBlur(0, inputList, resultList);
  hideOnBlur(1, inputList, resultList);
  hideOnBlur(2, inputList, resultList);
  hideOnBlur(3, inputList, resultList);
  hideOnBlur(0, clientInputList, clientResultList);
  hideOnBlur(1, clientInputList, clientResultList);

  function userOrClientSearchPopup(
    index,
    list,
    fetchFunction,
    funcChooseClient = null
  ) {
    list[index].addEventListener("input", (e) => {
      const searchText = e.target.value.trim();

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(function () {
        fetchFunction(searchText, index, funcChooseClient);
      }, 500);
    });
  }

  function chooseClient(index) {
    let clientEle = clientList[index - 2];
    clientEle.innerHTML = `<option value="" disabled selected>選択する</option>`;
    clientEle.removeAttribute("disabled");
    const userData = JSON.parse(inputList[index].dataset.allData);
    let referenceData = userData.reference_user_clients;
    referenceData.forEach((reference) => {
      let client = reference.client;
      let newClient = document.createElement("option");
      newClient.textContent = client.name;
      newClient.value = reference.id;
      newClient.dataset.role = reference.role;
      newClient.dataset.clientId = client.id;
      clientEle.appendChild(newClient);
    });
    clientEle.addEventListener("change", (e) => {
      fillInRole(e, index);
    });
  }

  function fillInRole(e, index) {
    let element = clientList[index - 2];
    let chosenOption = element.querySelector(
      `option[value="${clientList[index - 2].value}"]`
    );
    let role = chosenOption.dataset.role;
    roleList[index - 2].removeAttribute("disabled");
    roleList[index - 2].value = role;
    clientList[index - 2].dataset.clientId = chosenOption.dataset.clientId;
    submitList[index - 2].classList.remove("invisible");
  }

  function hideOnBlur(index, listOfInput, listOfResult) {
    listOfInput[index].addEventListener("blur", (e) => {
      setTimeout(() => {
        listOfResult[index].classList.add("d-none");
      }, 150);
    });
  }

  function fetchUsers(searchText, index, funcChooseClient = null) {
    fetch(`/admin/users/search?query=${searchText}`, {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    })
      .then((response) => response.json())
      .then((users) => {
        renderUsers(users, index, funcChooseClient);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  }

  function renderUsers(users, index, funcChooseClient = null) {
    inputList[index].dataset.userId = "";
    resultList[index].classList.remove("d-none");
    resultList[index].innerHTML = "";

    users.forEach((user) => {
      const userElement = document.createElement("div");
      userElement.classList.add("user-result-item");
      userElement.textContent = user.name || user.email;
      userElement.dataset.userId = user.id;

      userElement.addEventListener("click", function () {
        inputList[index].value = user.name || user.email;
        inputList[index].dataset.userId = user.id;

        inputList[index].dataset.allData = JSON.stringify(user);
        funcChooseClient(index);
        roleList[index - 2].setAttribute("disabled", true);
        submitList[index - 2].classList.add("invisible");

        resultList[index].innerHTML = ""; // Clear results after selection
      });

      resultList[index].appendChild(userElement);
    });
  }

  function fetchClients(searchText, index) {
    fetch(`/admin/clients/search?query=${searchText}`, {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    })
      .then((response) => response.json())
      .then((clients) => {
        renderClients(clients, index);
      })
      .catch((error) => {
        console.error("Error fetching clients:", error);
      });
  }

  function renderClients(clients, index) {
    clientInputList[index].dataset.clientId = "";
    clientResultList[index].classList.remove("d-none");
    clientResultList[index].innerHTML = "";

    clients.forEach((client) => {
      const clientElement = document.createElement("div");
      clientElement.classList.add("client-result-item");
      clientElement.textContent = client.name;
      clientElement.dataset.clientId = client.id;

      clientElement.addEventListener("click", function () {
        clientInputList[index].value = client.name;
        clientInputList[index].dataset.clientId = client.id;
        clientResultList[index].innerHTML = ""; // Clear results after selection
      });

      clientResultList[index].appendChild(clientElement);
    });
  }
});
