import { Toast } from 'bootstrap';

document.addEventListener('DOMContentLoaded', function() {
  var deleteError = function() {
      var errorMessage = document.querySelector('.tx-message');
    if (errorMessage) {
      errorMessage.remove();
    }
  };
  setTimeout(deleteError, 3000);
});
